import { Component } from '@angular/core';

@Component({
  selector: 'ngx-errors',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class ErrorsComponent {
}
