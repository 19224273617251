import { BaseResourceModel } from '../../../shared/models/base-resource.model';

export class ContentEngineModel extends BaseResourceModel {
  constructor(
    public id?: string,
    public cti_id?: string,
  ) {
    super();
  }

  static fromJson(jsonData: any): ContentEngineModel {
    return Object.assign(new ContentEngineModel(), jsonData);
  }
}
