import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Erros page
import { ErrorsComponent } from './errors.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { NbButtonModule, NbCardModule } from '@nebular/theme';

const routes: Routes = [{
  path: '',
  component: ErrorsComponent,
  children: [{
    path: '404',
    component: NotFoundComponent,
  }, {
    path: '500',
    component: InternalServerErrorComponent,
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    NbButtonModule,
    NbCardModule,
  ],
})
export class ErrorsRoutingModule { }

export const routedComponents = [
  ErrorsComponent,
  NotFoundComponent,
  InternalServerErrorComponent,
];
