import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { ErrorsRoutingModule, routedComponents } from './errors-routing.module';

// schemaGenerator
import { Bootstrap4FrameworkModule } from 'angular6-json-schema-form';
import { NoFrameworkModule } from 'angular6-json-schema-form';
import { NbCardModule } from '@nebular/theme';

@NgModule({
  imports: [
    ThemeModule,
    ErrorsRoutingModule,
    NoFrameworkModule,
    Bootstrap4FrameworkModule,
    NbCardModule,
  ],
  declarations: [
    ...routedComponents,
  ],
})
export class ErrorsModule { }
