import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ContentEngineModel } from './content-engine.model';
import { environment } from '../../../../environments/environment';

import { statusResource } from '../../../shared/models/status-resource.model';
import { BaseResourceService } from '../../../shared/services/base-resource.service';

export interface ContentEngineResponse {
  schema_list: string;
  cti_title: string;
  data: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class ContentEngineService extends BaseResourceService<ContentEngineModel> {

  private static env = environment.APIs.IpsenseForms;

  constructor(protected injector: Injector) {
    super(
      `${ContentEngineService.env.BasePath}/${ContentEngineService.env.ContentEngine}`,
     injector,
     ContentEngineModel.fromJson,
     );
  }

  getAllByContentInstanceId(contentInstanceId: string): Observable<ContentEngineResponse> {
    const url = `${this.apiPath}?cti_id=${contentInstanceId}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
    );
  }

  geByCTIformItemId(contentInstanceId: string, formItemId: string): Observable<any> {
    const url = `${this.apiPath}?cti_id=${contentInstanceId}&form_item_id=${formItemId}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
    );
  }

  updateCTIformItemId(contentInstanceId: string, formItemId: string, resource: any): Observable<any> {

    const url = `${this.apiPath}?cti_id=${contentInstanceId}&form_item_id=${formItemId}`;
    return this.http.put(url, {
      action: statusResource.EDITED,
      formData: resource,
    }).pipe(
      map(() => resource),
      catchError(this.handleError),
    );
  }

  createCTIFormItem(cti_id: string, resource: any): Observable<any> {
    const url = `${this.apiPath}?cti_id=${cti_id}`;
    return this.http.post(url, {
      action: statusResource.CREATED,
      formData: resource,
    })
    .pipe(
      map(() => resource),
      catchError(this.handleError),
    );
  }

  deleteCTIFormItem(cti_id: string, form_item_id: string): Observable<any> {
    const url = `${this.apiPath}?cti_id=${cti_id}&form_item_id=${form_item_id}`;

    return this.http.delete(url).pipe(
      map(() => null),
      catchError(this.handleError),
    );
  }
}
