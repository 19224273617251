import { NbMenuService } from '@nebular/theme';
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-internal-server-error',
  styleUrls: ['./internal-server-error.component.scss'],
  templateUrl: './internal-server-error.component.html',
})
export class InternalServerErrorComponent {

  constructor(private menuService: NbMenuService) { }

  goToHome() {
    this.menuService.navigateHome();
  }
}
